<template>
	<b-card title="Vendor List">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">Nama Vendor :</label>
					<input 
						id="name"
						type="text" 
						class="form-control"
						placeholder="Search: name"
						v-model="filter.keyword" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="category">Category:</label>
					<v-select
						id="category"
						v-model="filter.category_name"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="vendor_category_name"
						placeholder="Pilih Category"
						:options="category"
						:reduce="category => category.vendor_category_name"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="provinsi">Provinsi :</label>
					<v-select
						id="provinsi"
						v-model="filter.province"
						placeholder="-- Pilih Provinsi --"
						label="name"
						:options="listProvince"
						:reduce="(listProvince) => listProvince.id"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="city">Kota :</label>
					<v-select
						id="city"
						v-model="filter.city"
						placeholder="-- Pilih Kota --"
						label="name"
						:options="listCity"
						:reduce="(listCity) => listCity.id"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Start Date :</label>
					<flat-pickr
						v-model="filter.start_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							locale: {
								rangeSeparator: ' - ',
							},
							maxDate: new Date(),
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>End Date :</label>
					<flat-pickr
						v-model="filter.end_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							locale: {
								rangeSeparator: ' - ',
							},
							maxDate: new Date(),
						}"
					/>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="filter = {}" 
				>
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export
					@click="getDataExport"
				>
					Export
				</button>
				
			</b-col>
			<Export
				:is-loading="isLoading"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		
		<Table 
			:result="result"
			:vendor="vendor"
			:is-loading="isLoading"
			:get-data="getData"
			:delete-item="deleteItem"
			:current-page="currentPage"
			:update-flag="updateFlag"
			:update-recommendation="updateRecommendation"
			:update-verified="updateVerified"
			@filter="getFilter"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/Export.vue'
import Table from '@/components/vendor-list/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: [],
			vendor: {},
			link:'',
			isLoadingExport: false,
			filter: {},
			listAge: [],
			resultExport: [],
			sort: '',
			listProvince: [],
			listCity: [],
			category: [],
			gender: [
				{ 
					title: 'Wanita',
					value: 'P'
				}, 
				{ 
					title: 'Laki-laki',
					value: 'L'
				}, 
			],
			filter: {
				keyword: '',
				email: '',
				city: '',
				province: '',
				gender: '',
				range_age: '',
				start_date: '',
				end_date: '',
				order_type: '',
				category_name: '',
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
		"filter.province": function (data) {
			if (data) {
				if (data != this.filter.province) {
				this.filter.city = "";
				}
				this.getCity(data);
			}
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	mounted() {
		this.getListAge();
		this.getProvince();
		this.categoryName();
		// this.getCity();
	},
	methods: {
		getFilter(value) {
			this.filter.order_type = value.order_type
		},
		submitFilter() {
			this.getData(this.currentPage)
		},
		getDataExport() {
			this.isLoadingExport = true
			this.$http.get('/admin/export/vendor/list'
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
		clearFilter() {
			this.filter = {
				keyword: '',
				email: '',
				city: '',
				province: '',
				gender: '',
				range_age: '',
				start_date: '',
				end_date: '',
				order_type: ''
			}
		},
		categoryName() {
			this.$http.get('/admin/vendor-category')
			.then(response => {
				this.category = response.data.data
			})
		},
		exportNow() {
			this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$http.post('/admin/export/vendor/export', exportParam).then(response => {
				if(response) {
					this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					})
					this.$bvModal.hide('form-modal-export')
					this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
			})
		},
		getListAge() {
			this.$http.get('/admin/list-range-umur')
			.then(response => {
				this.listAge = response.data.data
			})
		},
		getProvince() {
			this.$http.get('/admin/util/province/1')
			.then(response => {
				this.listProvince = response.data.data
			})
		},
		getCity(data) {
			this.$http.get(`/admin/util/city/${data}`)
			.then(response => {
				this.listCity = response.data.data
			})
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get('/admin/vendor', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.vendor = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
			})	
		},
		deleteItem(slug) {
			this.$swal({
				title: 'Are you sure?',
				text: 'Are you sure to delete this vendor?',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
				this.isLoading = true
				this.$http.delete(`/admin/vendor/${slug}`)
					.then(response => {
					this.getData(this.currentPage)
					this.$swal({
						icon: 'success',
						title: 'Success!',
						text: 'Vendor successfully deleted',
						customClass: {
						confirmButton: 'btn btn-success',
						},
					})
					this.isLoading = false
					})
					.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					})
				}
			})
		},
		updateFlag(id, status) {
			let pinText = status === true ? 'pin' : 'unpin';
			this.$swal({
				title: 'Are you sure?',
				text: `Are you sure to ${pinText} this vendor?`,
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					let payload = new FormData();
					payload.append('is_pinned',  status)
					this.isLoading = true
					this.$http.post(`/admin/vendor/set-pin-content/${id}`, payload, {
					headers: { "content-type": "multipart/form-data" },
				})
					.then(response => {
						this.getData(this.currentPage);
						this.$swal({
								icon: 'success',
								title: 'Success!',
								text: 'Vendor successfully updated',
								customClass: {
								confirmButton: 'btn btn-success',
							},
						});
				
						this.isLoading = false
					})
					.catch(error => {
						if (error.response.data.meta.messages.length > 0) {
							errorNotification(this, 'Oops!', error.response.data.meta.messages)
							this.getData(this.currentPage);
							this.isLoading = false
						}
					})
				}
			})
		},
		updateRecommendation(id, status) {
			let text = status === true ? 'add recommendation' : 'remove recommendation';
			this.$swal({
				title: 'Are you sure?',
				text: `Are you sure to ${text} this vendor?`,
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					let payload = new FormData();
					payload.append('is_recommendation',  status)
					this.isLoading = true
					this.$http.post(`/admin/vendor/set-recommendation/${id}`, payload, {
					headers: { "content-type": "multipart/form-data" },
				})
					.then(response => {
						this.getData(this.currentPage);
						this.$swal({
								icon: 'success',
								title: 'Success!',
								text: 'Vendor successfully updated',
								customClass: {
								confirmButton: 'btn btn-success',
							},
						});
				
						this.isLoading = false
					})
					.catch(error => {
						if (error.response.data.meta.messages.length > 0) {
							errorNotification(this, 'Oops!', error.response.data.meta.messages)
							this.getData(this.currentPage);
							this.isLoading = false
						}
					})
				} else {
					this.getData(this.currentPage);
					this.isLoading = false
				}
			})
		},
		updateVerified(id, status) {
			let pinText = status === true ? 'verified' : 'unverified';
			this.$swal({
				title: 'Are you sure?',
				text: `Are you sure to ${pinText} this vendor?`,
				icon: 'warning',
				showCancelButton: true,
				customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					let payload = new FormData();
					payload.append('is_verified',  status)
					this.isLoading = true
					this.$http.post(`/admin/vendor/set-verified/${id}`, payload, {
					headers: { "content-type": "multipart/form-data" },
				})
					.then(response => {
						this.getData(this.currentPage);
						this.$swal({
								icon: 'success',
								title: 'Success!',
								text: 'Vendor successfully updated',
								customClass: {
								confirmButton: 'btn btn-success',
							},
						});
				
						this.isLoading = false
					})
					.catch(error => {
						if (error.response.data.meta.messages.length > 0) {
							errorNotification(this, 'Oops!', error.response.data.meta.messages)
							this.getData(this.currentPage);
							this.isLoading = false
						}
					})
				}
			})
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>